// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
//Turbolinks.start()
ActiveStorage.start()

window.$ = jQuery
window.Rails = Rails

require("bootstrap");

import "fontawesome-pro-6.0.0-beta1-web/js/all.min";
import "startbootstrap-sb-admin-2/js/sb-admin-2";

import "datatables.net-fixedheader";
import "datatables.net-bs4";

import "bootstrap4-notify";

import "jquery.easing/jquery.easing";

import "chartkick/chart.js"; // dashboardや分析画面で使う

// chartkickが読み込んだChartにpluginを登録する
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chartkick.adapters[0].library.register(ChartDataLabels);

$(function() {
  //$('.toast').toast('show');

  // なぜかsb-admin-2.js内で実行してもうごかないのでこっちで実行する
  $("#sidebarToggle, #sidebarToggleTop").off('click');
  $("#sidebarToggle, #sidebarToggleTop").on('click', function(e) {
    $("body").toggleClass("sidebar-toggled");
    $(".sidebar").toggleClass("toggled");
    if ($(".sidebar").hasClass("toggled")) {
      $('.sidebar .collapse').collapse('hide');
    };
  });

  // onload時に調整が必要なのでsb-admin-2.jsのresize eventからコピー
  if ($(window).width() < 768) {
    $('.sidebar .collapse').collapse('hide');
  };
  // Toggle the side navigation when window is resized below 480px
  if ($(window).width() < 480 && !$(".sidebar").hasClass("toggled")) {
    $("body").addClass("sidebar-toggled");
    $(".sidebar").addClass("toggled");
    $('.sidebar .collapse').collapse('hide');
  };

  // お知らせクリック処理
  $('a.notice-link').click(function(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    var link_path = $(this).attr('href');
    var notice_id = $(this).attr('data-noticeid');
    // ajaxで既読にする
    $.ajax({
      url: `/notices/${notice_id}`,
      type: 'POST',
      data: { _method: 'GET' }
    }).done(function(data) {
      location.href = link_path;
    });
  }); // notice-link.click

  // cattles.jsで設定済みでないことを確認してから
  if (!window.__globalSearch) {
    window.__globalSearch = "app";
    $('#globalSearchBtn').on("click", function(evt) {
      if (window.__globalSearch == "cattles") {
        return;
      }
      var tag = $('#globalSearch').val();
      if (tag == '') {
        alert('検索したい番号を入力してください');
        return;
      }
      // ここにくるということはなんかしらtagが指定されている
      // ページ遷移させたいのでlocationで飛ばすか
      var u = new URL(location.href);
      var cid = u.searchParams.get('customer_id');
      var url = new URL('/dashboard/search', location.href); // queryが落ちることに注意
      url.searchParams.append('tag', tag);
      if (cid) {
        url.searchParams.append('customer_id', cid);
      }
      location.href = url.toString();
    });
  }

  // パスワード変更modal
  $('#pwdChangeBtn').click(function(evt) {
    evt.stopPropagation();
    evt.preventDefault();
    window.$('#pwdModal').modal('show');
    $.ajax({
      url: '/edit',
      type: 'GET'
    }).done(function(data) {
      $('#pwdModal div.modal-content').html(data);
      window.$('#pwdModal').modal('show');
      $('#pwdSubmitBtn').off("click");
      $('#pwdSubmitBtn').on('click', function(evt) {
        Rails.fire($('#pwd_form').get(0), 'submit');
      });
    });
  });

});

